import React, { useState, useEffect, useMemo } from 'react';
import ScratchCard from 'react-scratchcard';
import disableDevtool from 'disable-devtool';
import './App.css';

const App = () => {
  const [pulse, setPulse] = useState(false);
  const [showPopup, setShowPopup] = useState(false); 
  const [time, setTime] = useState(120); 

  useEffect(() => {
    // Disable right-click
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    // Disable F12, Ctrl+Shift+I, Ctrl+Shift+J, Ctrl+U
    document.addEventListener('keydown', (e) => {
      if (e.key === 'F12' || 
          (e.ctrlKey && e.shiftKey && e.key === 'I') || 
          (e.ctrlKey && e.shiftKey && e.key === 'J') || 
          (e.ctrlKey && e.key === 'U')) {
        e.preventDefault();
      }
    });

    if (time > 0) {
      const timer = setInterval(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(timer);
    }
  }, [time]);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const settings = {
    width: 350, 
    height: 350, 
    image: './raspar.png', 
    finishPercent: 72, 
    onComplete: () => {
      setPulse(true);
      setTimeout(() => {
        setShowPopup(true); 
      }, 1500);
    },
  };

  const images = [
    'image1.png', 'image1.png', 'image1.png',
    'image2.png', 'image2.png',
    'image3.png', 'image3.png',
    'image4.png', 'image4.png'
  ];

  const shuffleArray = (array) => {
    let shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const shuffledImages = useMemo(() => shuffleArray(images), []);

  return (
    <div className="App">
      <div className="top-bar">
        <span>Essa oferta acaba em: {formatTime(time)} min...</span>
      </div>
      <div className={`popup ${showPopup ? 'show' : ''}`}>
        <div className="popup-overlay">
          <h1>Você ganhou até <span>70 rodadas grátis + 100% de bônus +</span> chance de ser sorteado a um <span>iPhone 15. Agora, escolha as suas rodadas grátis</span></h1>
          <div className="link-container pulse">
            <a href="https://jonbet.cxclick.com/visit/?bta=36501&nci=5381" className="sc-dcJtft cUHoXX">
              <div><strong>10 rodadas grátis </strong> | Deposite R$20</div>
            </a>
            <a href="https://jonbet.cxclick.com/visit/?bta=36501&nci=5382" className="sc-dcJtft cUHoXX highlight">
              <div><strong>40 rodadas grátis </strong> | Deposite R$50</div>
            </a>
            <a href="https://jonbet.cxclick.com/visit/?bta=36501&nci=5385" className="sc-dcJtft cUHoXX">
              <div><strong>70 rodadas grátis </strong> | Deposite R$100</div>
            </a>
          </div>
          <a href="https://jonbet.cxclick.com/visit/?bta=36501&nci=5346">
            <img src="/btn.avif" className="sc-fqkwJk hLuGmd" alt="Botão" />
          </a>
        </div>
        <img src="./popup.png" alt="Popup" className="popup-image" />
      </div>
      <div className="scratch-container">
        <img src="./titulo.png" alt="Titulo" className="titulo" />
        <div className="scratch-card-wrapper">
          <div className="image-container">
            <img src="./backraspar.png" className="backraspar" alt="Background" />
            <div className="grid-overlay">
              <div className="grid-container">
                {shuffledImages.map((img, index) => (
                  <div key={index} className={`grid-item ${img === 'image1.png' && pulse ? 'pulse' : ''}`}>
                    <img src={`./${img}`} alt={`Grid item ${index + 1}`} />
                  </div>
                ))}
              </div>
            </div>
            <div className="scratch-card">
              <ScratchCard {...settings} />
            </div>
          </div>
          <img src="./backbaixo.png" className="backbaixo" alt="Back Baixo" />
        </div>
      </div>
    </div>
  );
};

export default App;
